import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthInterceptor} from './common/auth-interceptor';
import {LoginModalComponent} from './login-modal/login-modal.component';
import {FormsModule} from '@angular/forms';
import {SignUpModalComponent} from './sign-up-modal/sign-up-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import {LocationStrategy, HashLocationStrategy, CommonModule} from '@angular/common';
import {ClassesNumberModalComponent} from './classes-number-modal/classes-number-modal.component';
import {StatementModalComponent} from './statement-modal/statement-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgZorroAntdMobileModule, } from 'ng-zorro-antd-mobile';
import {PhotoSwipeComponent} from './folder/photo-swipe/photo-swipe.component';
import {TimetableViewComponent} from './folder/timetable-view/timetable-view.component';

// eslint-disable-next-line @typescript-eslint/naming-convention,prefer-arrow/prefer-arrow-functions
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/' ,'.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginModalComponent,
    SignUpModalComponent,
    ClassesNumberModalComponent,
    StatementModalComponent,
    PhotoSwipeComponent,
    TimetableViewComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    QRCodeModule,
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'zh-HK',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgZorroAntdMobileModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
