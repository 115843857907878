import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, of } from 'rxjs';

import {
  Response,
  User,
  LoginRequest,
  Page,
  Notice,
  ResponseList,
  Teacher,
  CourseSchedule,
  CourseInfo,
  CourseScheduleBook,
  Sign,
  SignRequest,
  StudentSchedule,
  StudentCoursePackage,
  StudentClassRecord,
  Order,
  CoursePackage,
  PlaceOrder,
  RegisterVerificationCode,
  ResetPassword,
  CourseScheduleWeek, Popup
} from './interface';
import {catchError, tap} from 'rxjs/operators';
import {MessageService} from './message.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private message: MessageService
  ) {
  }

  getPopup(): Observable<ResponseList<Popup>> {
    return this.http.get<ResponseList<Popup>>(`/api/platform/notice/popup`).pipe();
  }

  login(loginRequest: LoginRequest): Observable<Response<User>> {
    const url = '/api/platform/user/login';
    return this.http.post<Response<User>>(url, loginRequest).pipe(
      catchError(this.handleError<Response<User>>('login'))
    );
  }

  sign(sign: SignRequest): Observable<Response<Sign>> {
    const url = '/api/platform/student/register';
    return this.http.post<Response<Sign>>(url, sign).pipe(
      catchError(this.handleError<Response<Sign>>('sign'))
    );
  }

  studentInfo(): Observable<Response<Sign>> {
    return this.http.get<Response<Sign>>(`/api/platform/student/info`).pipe();
  }

  switchStudent(studentId: string): Observable<Response<Sign>> {
    return this.http.put<Response<Sign>>(`/api/platform/student/switch/${studentId}`, null).pipe();
  }

  updateStudent(student: Sign): Observable<Response<Sign>>  {
    return this.http.put<Response<Sign>>(`/api/platform/student/info`, student).pipe();
  }

  exchangePackage(): Observable<Response<null>> {
    return this.http.post<Response<null>>('/api/platform/student/exchangePackage', null).pipe(
      catchError(this.handleError<Response<null>>('exchangePackage'))
    );
  }

  courseBalance(): Observable<Response<number>> {
    return this.http.get<Response<number>>(`/api/platform/student/courseBalance`).pipe(
      catchError(this.handleError<Response<number>>('courseBalance'))
    );
  }

  schedule(): Observable<ResponseList<StudentSchedule>> {
    return this.http.get<Response<StudentSchedule>>(`/api/platform/student/schedule`).pipe(
      catchError(this.handleError<Response<StudentSchedule>>('schedule'))
    );
  }

  coursePackage(): Observable<Response<StudentCoursePackage>> {
    return this.http.get<Response<StudentCoursePackage>>(`/api/platform/student/coursePackage`).pipe(
      catchError(this.handleError<Response<StudentCoursePackage>>('coursePackage'))
    );
  }

  classRecord(request: HttpParams): Observable<Page<StudentClassRecord>> {
    return this.http.get<Response<StudentClassRecord>>(`/api/platform/student/classRecord`, {params: request}).pipe(
      catchError(this.handleError<Response<StudentClassRecord>>('classRecord'))
    );
  }

  sendRegisterVerificationCode(request: RegisterVerificationCode): Observable<Response<null>>  {
    return this.http.post<Response<null>>(`/api/platform/student/sendRegisterVerificationCode`, request).pipe();
  }

  sendInfoRegisterVerificationCode(request: HttpParams): Observable<Response<null>>  {
    return this.http.post<Response<null>>(`/api/platform/student/sendChangeEmailCode`, {}, {params: request}).pipe();
  }

  forgotPassword(request: RegisterVerificationCode): Observable<Response<null>>  {
    return this.http.post<Response<null>>(`/api/platform/student/forgotPassword`, request).pipe();
  }

  resetPassword(request: ResetPassword): Observable<Response<null>>  {
    return this.http.post<Response<null>>(`/api/platform/student/resetPassword`, request).pipe();
  }

  getOrderPage(request: HttpParams): Observable<Page<Order>> {
    return this.http.get<Page<Order>>(`/api/platform/order`, {params: request}).pipe(
      catchError(this.handleError<Page<Order>>('getOrderPage'))
    );
  }

  getOrderDetail(id: string): Observable<Response<Order>> {
    return this.http.get<Response<Order>>(`/api/platform/order/${id}`).pipe();
  }

  // getOrderPayUrl(id: string): Observable<Response<string>> {
  //   return this.http.get<Response<string>>(`/api/platform/order/${id}/payUrl`).pipe();
  // }

  cancelOrder(id: string): Observable<Response<string>> {
    return this.http.put<Response<string>>(`/api/platform/order/${id}/cancel`, null).pipe();
  }

  getCoursePackageList(): Observable<ResponseList<CoursePackage>> {
    return this.http.get<ResponseList<CoursePackage>>(`/api/platform/coursePackage/price`).pipe();
  }

  placeOrder(request: PlaceOrder): Observable<Response<string>>  {
    return this.http.post<Response<string>>(`/api/platform/coursePackage/placeOrder`, request).pipe();
  }

  logout(): Observable<Response<any>> {
    const url = `/api/user/logout`;
    return this.http.post<any>(url, {});
  }

  // updatePassword(request: UpdatePassword): Observable<Response<UpdatePassword>>  {
  //   return this.http.put<Response<UpdatePassword>>(`/api/user/password`, request).pipe(
  //     catchError(this.handleError<Response<UpdatePassword>>('updatePassword', null))
  //   );
  // }

  // 获取当前登录用户信息
  // getLoginInfo(): Promise<Response<User>> {
  //   const url = '/api/user/info';
  //   return this.http.get<Response<User>>(url).toPromise<Response<User>>();
  // }

  getNoticeList(request: HttpParams): Observable<ResponseList<Notice>> {
    return this.http.get<ResponseList<Notice>>(`/api/platform/notice`, {params: request}).pipe();
  }

  getCourseList(): Observable<ResponseList<CourseInfo>> {
    return this.http.get<ResponseList<CourseInfo>>(`/api/platform/course`).pipe();
  }

  getTeacherList(): Observable<ResponseList<Teacher>> {
    return this.http.get<ResponseList<Teacher>>(`/api/platform/course/teacher`).pipe();
  }

  getCourseScheduleList(request: HttpParams): Observable<ResponseList<CourseSchedule>> {
    return this.http.get<ResponseList<CourseSchedule>>(`/api/platform/course/schedule`, {params: request}).pipe();
  }

  getCourseScheduleDetail(id: string): Observable<Response<CourseSchedule>> {
    return this.http.get<Response<CourseSchedule>>(`/api/platform/course/schedule/${id}`).pipe();
  }

  checkReservePackage(request: HttpParams): Observable<Response<number>>  {
    return this.http.get<Response<number>>(`/api/platform/course/checkReservePackage`,{params:request}).pipe();
  }

  checkReserveFreeCancel(reserveId: string): Observable<Response<boolean>> {
    return this.http.put<Response<boolean>>(`/api/platform/course/reserve/${reserveId}/freeCancelCheck`, null).pipe();
  }

  getMyCourseScheduleList(): Observable<ResponseList<StudentSchedule>> {
    return this.http.get<ResponseList<StudentSchedule>>(`/api/platform/student/schedule`).pipe();
  }

  bookCourseSchedule(request: CourseScheduleBook): Observable<Response<void>>  {
    return this.http.post<Response<void>>(`/api/platform/course/reserve`, request).pipe();
  }

  cancelBookCourseSchedule(id: string): Observable<Response<void>>  {
    return this.http.put<Response<void>>(`/api/platform/course/reserve/${id}/cancel`, null).pipe();
  }

  joinWaitingCourseSchedule(request: CourseScheduleBook): Observable<Response<void>>  {
    return this.http.post<Response<void>>(`/api/platform/course/joinWaiting`, request).pipe();
  }

  cancelWaitingCourseSchedule(id: string): Observable<Response<void>>  {
    return this.http.post<Response<void>>(`/api/platform/course/waiting/${id}/cancel`, null).pipe();
  }

  upload(request: FormData): Observable<Response<string>>  {
    // this.loadingService.start();
    const headers = new HttpHeaders({
      mimeType: 'multipart/form-data'
    });
    const options = { headers: headers };
    return this.http.post<Response<string>>(`/api/platform/file/compression`, request, options).pipe(
      // tap(
      //   date => this.loadingService.end(),
      //   error => this.loadingService.end()
      // )
    );
  }

  getCourseScheduleWeek(request: HttpParams): Observable<Response<CourseScheduleWeek>> {
    return this.http.get<Response<CourseScheduleWeek>>(`/api/platform/course/week`, {params: request}).pipe();
  }

  updateCoursePackageSort(request: string[]): Observable<Response<null>>  {
    return this.http.put<Response<null>>(`/api/platform/student/package/openSort`, request).pipe();
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // err modal
      // Let the app keep running by returning an empty result.
      this.message.error('There was a problem with the service, please try again later');
      return of(result as T);
    };
  }

}
