import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UtilsService} from '../common/utils.service';

@Component({
  selector: 'app-statement-modal',
  templateUrl: './statement-modal.component.html',
  styleUrls: ['./statement-modal.component.scss'],
})
export class StatementModalComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    public utilsService: UtilsService,
  ) { }

  ngOnInit() {}

  async closeModel(flag: boolean) {
    await this.modalController.dismiss(flag);
  }

}
