import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-classes-number-modal',
  templateUrl: './classes-number-modal.component.html',
  styleUrls: ['./classes-number-modal.component.scss'],
})
export class ClassesNumberModalComponent implements OnInit {

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {}

  async closeModel() {
    await this.modalController.dismiss();
  }
}
