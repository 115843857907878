import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UtilsService} from '../common/utils.service';
import {UserService} from '../common/user.service';
import {MessageService} from '../common/message.service';
import {localStorageTokenName} from '../common/config';
import {Router} from '@angular/router';
import {checkEmailFormat} from '../common/utils';
import {ApiService} from '../common/api.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  // @Input() courseSchedule: CourseSchedule;
  public account = '';
  public password = '';
  email = '';
  forgetModalFlag = false;

  constructor(
    private router: Router,
    private modalController: ModalController,
    public utilsService: UtilsService,
    private userService: UserService,
    private message: MessageService,
    private apiService: ApiService,
  ) {}

  ngOnInit() {}

  async closeModel(flag: boolean) {
    await this.modalController.dismiss(flag);
  }

  async login() {
    if (!this.account || !this.password || this.account === '' || this.password === '') {
      this.message.warning(this.utilsService.accountEmptyText);
      return;
    }
    if (this.password.length < 8) {
      this.message.warning(this.utilsService.passwordsDigitsText);
      return;
    }
    const data = await this.userService.loginPromise({account: this.account, password: this.password});
    if (data.code === 200) {
      localStorage.setItem(localStorageTokenName, data.data.token);
      // this.permissionValueSet.clear();
      // for (const item of data.data.permissionList ? data.data.permissionList : []) {
      //   this.permissionValueSet.add(item);
      // }
      this.utilsService.loginFlag = true;
      this.utilsService.allowPurchasesFlag = data.data.allowPurchases;
      this.message.success(this.utilsService.loginSuccessText);
      this.closeModel(true);
    }else {
      localStorage.removeItem(localStorageTokenName);
    }
  }

  forgetPassword(): void {
    this.email = this.account;
    this.forgetModalFlag = true;
  }

  forgetPasswordApi(): void {
    this.apiService.forgotPassword({email: this.email})
      .subscribe((data) => {
        if (data.code === 200) {
          this.message.success(this.utilsService.sendForgetPasswordEmailText);
          this.dismiss();
        }
      });
  }

  handleCancelOfForget(): void {
    this.email = '';
    this.forgetModalFlag = false;
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  checkEmail(): boolean {
    if (!this.email || this.email === '') {
      return true;
    }
    return checkEmailFormat(this.email);
  }

}
