import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {enLanguage, localStorageLanguage, localStorageLogin, localStorageTokenName} from './config';
import {MessageService} from './message.service';

// http请求拦截器
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private message: MessageService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(localStorageTokenName);
    if (token) { // 如果有token，就添加
      req = req.clone({
        setHeaders: {
          Authorization: token
        },
        withCredentials: true
      });
    }
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // 自定义异常，业务自定义异常在这里校验
          if (event.body.code && event.body.code !== 200) {
            if (localStorage.getItem(localStorageLanguage) === enLanguage) {
              this.message.error(event.body.msg);
            }else {
              this.message.error(event.body.cnMsg);
            }
          }
          if (event.body.code === 401 || event.body.code === 403) {
            localStorage.setItem(localStorageLogin, '0');
            localStorage.removeItem(localStorageTokenName);
            if (localStorage.getItem(localStorageLanguage) === enLanguage) {
              this.message.error('Login invalid, please log in again');
            }else {
              this.message.error('登錄失效，請重新登錄');
            }
          }
        }
      }, err => { // 统一处理所有的http错误
        if (err instanceof HttpErrorResponse) {
          console.log(err);
          if (err.status === 500 || err.status === 502 || err.status === 504) {
            if (localStorage.getItem(localStorageLanguage) === enLanguage) {
              this.message.error('Encountered an unexpected problem while processing the request');
            }else {
              this.message.error('在處理請求時遇到了一個意外的問題');
            }
          } else if (err.status === 501) {
            if (localStorage.getItem(localStorageLanguage) === enLanguage) {
              this.message.error('Does not support this request method');
            }else {
              this.message.error('不支持這種請求方式');
            }
          } else if (err.status === 503) {
            if (localStorage.getItem(localStorageLanguage) === enLanguage) {
              this.message.error('Please try again later');
            }else {
              this.message.error('請稍後再試');
            }
          }
          if (err.status === 401 || err.status === 403) {
            localStorage.setItem(localStorageLogin, '0');
            localStorage.removeItem(localStorageTokenName);
            if (localStorage.getItem(localStorageLanguage) === enLanguage) {
              this.message.error('Login invalid, please log in again');
            }else {
              this.message.error('登錄失效，請重新登錄');
            }
            // this.userService.logout();
          }
          return;
        }
      })
    );
  }
}
