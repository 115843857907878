import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IonModal, LoadingController, ModalController, PopoverController} from '@ionic/angular';
import {UtilsService} from '../common/utils.service';
import {UserService} from '../common/user.service';
import {MessageService} from '../common/message.service';
import {BankCardRequest, Sign, StudentTypeEnum} from '../common/interface';
import {ApiService} from '../common/api.service';
import {checkEmailFormat, yearsOld} from '../common/utils';
import {addDays, addYears, format, subYears} from 'date-fns';
import {StatementModalComponent} from '../statement-modal/statement-modal.component';

@Component({
  selector: 'app-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.scss'],
})
export class SignUpModalComponent implements OnInit, OnDestroy {
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('studentCard')
  studentCard: any;
  // @Input() courseSchedule: CourseSchedule;
  childrenType = StudentTypeEnum.CHILDREN.valueOf();
  adultsType = StudentTypeEnum.ADULTS.valueOf();
  highSchoolType = StudentTypeEnum.HIGH_SCHOOL_STUDENTS.valueOf();
  collegeStudentsType = StudentTypeEnum.COLLEGE_STUDENTS.valueOf();
  public password = '';
  dateLimits: string = new Date().toISOString();
  minDateLimitsOfChildren: string = subYears(addDays(new Date(), 1), 17).toISOString();
  minDateLimitsOfNotChildren: string = subYears(addDays(new Date(), 1), 120).toISOString();
  birthdate: Date;
  birthdateStr: string;
  yearOldStr = '';
  studentCardSrc = '';
  studentCardExpiryDate: Date;
  studentCardExpiryDateStr: string;
  firstName = '';
  lastName = '';
  gender = '';
  email = '';
  emailCode = '';
  areaCode = '852';
  phoneNumber = '';
  studentType = '';
  cardNumber: string;
  cardNumberList: BankCardRequest[] = [];
  successSign: Sign;

  backwardsTimes = 60;
  backwardsTimer: any;
  statement = false;

  yearList: number[] = this.yearForSix();

  constructor(
    private modalController: ModalController,
    public utilsService: UtilsService,
    private userService: UserService,
    private message: MessageService,
    private apiService: ApiService,
    private loadingController: LoadingController,
    private popoverController: PopoverController,
  ) {}

  ngOnInit() {
    this.yearForBirthdate();
  }

  yearForSix(): number[] {
    const now = new Date();
    const yearList = [];
    yearList.push(now.getFullYear());
    for (let i = 1; i < 6; i++) {
      yearList.push(addYears(now, i).getFullYear());
    }
    return yearList;
  }

  starTimer(): void {
    if (!this.email || this.email === '') {
      this.message.warning(this.utilsService.emailText + this.utilsService.isRequiredText);
      return;
    }
    // eslint-disable-next-line max-len
    if (checkEmailFormat(this.email)) {
      this.message.warning(this.utilsService.emailErrText);
      return;
    }
    this.apiService.sendRegisterVerificationCode({email: this.email})
      .subscribe((data) => {
        if (data.code === 200) {
          this.message.success(this.utilsService.sendAlreadyPurchasedText);
          // 倒計時禁用
          this.backwardsTimes -= 1;
          this.backwardsTimer = setInterval(() => {
            // 倒計時結束，銷毀定時任務
            if (this.backwardsTimes < 0) {
              this.backwardsTimes = 60;
              clearInterval(this.backwardsTimer);
            }else {
              this.backwardsTimes -= 1;
            }
          }, 1000);
        }
      });
  }

  checkEmail(): boolean {
    if (!this.email || this.email === '') {
      return true;
    }
    return checkEmailFormat(this.email);
  }

  ngOnDestroy(): void{
    this.backwardsTimes = 60;
    clearInterval(this.backwardsTimer);
  }

  async closeModel(flag: boolean) {
    await this.modalController.dismiss(flag);
  }

  async sign() {
    if (!this.firstName || this.firstName === '') {
      this.message.warning(this.utilsService.firstNameText + this.utilsService.isRequiredText);
      return;
    }
    if (!this.lastName || this.lastName === '') {
      this.message.warning(this.utilsService.lastNameText + this.utilsService.isRequiredText);
      return;
    }
    if (!this.gender || this.gender === '') {
      this.message.warning(this.utilsService.genderText + this.utilsService.isRequiredText);
      return;
    }
    if (!this.studentType || this.studentType === '') {
      this.message.warning(this.utilsService.typeText + this.utilsService.isRequiredText);
      return;
    }
    // 如果是儿童，校验出生日期是否填了
    if (this.studentType === this.childrenType && this.birthdate == null) {
      this.message.warning(this.utilsService.birthdateText + this.utilsService.isRequiredText);
      return;
    }
    // // 如果是学生，校验学生证与学生证是否填了
    // if (this.studentType === this.highSchoolType || this.studentType === this.collegeStudentsType) {
    //   if (this.studentCardSrc === '') {
    //     this.message.warning(this.utilsService.studentCardText + this.utilsService.isRequiredText);
    //     return;
    //   }
    //   if (this.studentCardExpiryDate == null) {
    //     this.message.warning(this.utilsService.studentCardExpiryDateText + this.utilsService.isRequiredText);
    //     return;
    //   }
    // }
    if (!this.email || this.email === '') {
      this.message.warning(this.utilsService.emailText + this.utilsService.isRequiredText);
      return;
    }
    if (!this.areaCode || this.areaCode === '') {
      this.message.warning(this.utilsService.areaCodeText + this.utilsService.isRequiredText);
      return;
    }
    if (!this.phoneNumber || this.phoneNumber === '') {
      this.message.warning(this.utilsService.phoneNumberText + this.utilsService.isRequiredText);
      return;
    }
    if (this.phoneNumber && this.phoneNumber !== ''
      && (this.phoneNumber.length < 6 || this.phoneNumber.length > 20)) {
      this.message.warning(this.utilsService.phoneNumberText + this.utilsService.wrongFormatText);
      return;
    }
    // eslint-disable-next-line max-len
    if (checkEmailFormat(this.email)) {
      this.message.warning(this.utilsService.emailErrText);
      return;
    }
    if (!this.emailCode || this.emailCode === '') {
      this.message.warning(this.utilsService.emailCodeText + this.utilsService.isRequiredText);
      return;
    }
    if (this.password.length < 8) {
      this.message.warning(this.utilsService.passwordsDigitsText);
      return;
    }
    this.apiService.sign({
      account: this.email,
      password: this.password,
      firstName: this.firstName,
      lastName: this.lastName,
      gender: this.gender,
      areaCode: this.areaCode && this.phoneNumber ? this.areaCode : '',
      phone: this.areaCode && this.phoneNumber ? this.phoneNumber : '',
      email: this.email,
      type: this.studentType,
      card: this.studentType === this.highSchoolType || this.studentType === this.collegeStudentsType ? this.studentCardSrc : null,
      cardExpirationTime: (this.studentType === this.highSchoolType || this.studentType === this.collegeStudentsType)
      && this.studentCardExpiryDate ? new Date(this.studentCardExpiryDate).getTime() : null,
      birthday: this.birthdate ? new Date(this.birthdate).getTime() : null,
      verificationCode: this.emailCode,
      bankCardList: this.cardNumberList
    }).subscribe((data) => {
      if (data.code === 200) {
        this.successSign = data.data;
        this.message.success(this.utilsService.registerText + this.utilsService.successText);
        this.closeModel(true);
      }
    });
  }
  addCard(): void {
    if (!this.cardNumber || this.cardNumber.toString().length < 16) {
      this.message.warning(this.utilsService.cardLengthErrText);
      return;
    }
    this.cardNumberList.push({cardNumber: this.cardNumber,defaultCard: this.cardNumberList.length === 0});
    this.cardNumber = null;
  }
  removeCard(index: number): void {
    const defaultCard = this.cardNumberList[index].defaultCard;
    this.cardNumberList.splice(index, 1);
    if (defaultCard && this.cardNumberList.length > 0) {
      this.cardNumberList[0].defaultCard = true;
    }
  }
  setDefaultCard(index: number): void {
    this.cardNumberList.forEach(item => {
      item.defaultCard = false;
    });
    this.cardNumberList[index].defaultCard = true;
  }

  birthdateChange(): void {
    if (this.birthdate) {
      if (this.birthdateStr !== format(new Date(this.birthdate), 'dd/MM/yyyy')) {
        this.popoverController.dismiss();
        // this.dismiss();
      }
      this.birthdateStr = format(new Date(this.birthdate), 'dd/MM/yyyy');
    }
    this.yearForBirthdate();
  }

  studentCardExpiryDateChange(): void {
    if (this.studentCardExpiryDate) {
      if (this.studentCardExpiryDateStr !== format(new Date(this.studentCardExpiryDate), 'dd/MM/yyyy')) {
        this.popoverController.dismiss();
        // this.dismiss();
      }
      this.studentCardExpiryDateStr = format(new Date(this.studentCardExpiryDate), 'dd/MM/yyyy');
    }
  }

  async fileChange(event) {
    const fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      const loading = await this.loadingController.create({
        message: this.utilsService.loadingText
      });
      await loading.present();
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      this.apiService.upload(formData).subscribe((data) => {
          this.loadingController.dismiss();
          if (data.code === 200) {
            this.studentCardSrc = data.data;
          }
        }
      );
    }
  }

  uploadStudentCard(): void {
    this.studentCard.nativeElement.click();
  }

  yearForBirthdate(): void {
    if (this.birthdate) {
      this.yearOldStr = yearsOld(new Date(this.birthdate)) + ' ' + this.utilsService.ageText;
    }else {
      this.yearOldStr = '-' + this.utilsService.ageText;
    }
  }

  async showStatementModal() {
    const modal = await this.modalController.create({
      component: StatementModalComponent,
      mode: 'ios',
      cssClass: this.utilsService.pcFlag ? 'app-modal app-statement-modal-pc' : '',
      // componentProps: {
      //   courseSchedule: item
      // }
    });
    // 登录窗口关闭回调
    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null && modelData.data) {
        console.log(modelData.data);
        this.statement = modelData.data;
      }
    });
    await modal.present();
  }
}
