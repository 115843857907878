import {Component, OnDestroy, OnInit} from '@angular/core';
import {getPlatforms, LoadingController, ModalController, Platform} from '@ionic/angular';
import {UtilsService} from './common/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './common/user.service';
import {
  enLanguage,
  localStorageLanguage,
  localStorageLogin,
  localStorageTokenName, popupStorage,
  zhHKLanguage
} from './common/config';
import {ApiService} from './common/api.service';
import {Router} from '@angular/router';
import {nameRules, randomSixDigitCharacter} from './common/utils';
import {Popup, SignOtherAccount} from './common/interface';
import {MessageService} from './common/message.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity: 0}))
      ])
    ])
  ],
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy{
  language = 'zh-HK';

  // 定时器
  timer: any;

  switchModalFlag = false;
  // 弹窗配置
  popupShow = false;
  popup: Popup | null;
  popupList: Popup[] = [];
  selectImgIndex = 0;

  // 用于 IOS 设备单独做适配用
  isIOS = false;

  constructor(
    private router: Router,
    private platform: Platform,
    public utilsService: UtilsService,
    public userService: UserService,
    public translate: TranslateService,
    private apiService: ApiService,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private message: MessageService,
  ) {
    this.initTranslate();
    // i18n 初始化
    this.utilsService.i18nOnInit();
  }
  initTranslate(){
    //设置翻译字符串的默认语言和当前语言
    if (localStorage.getItem(localStorageLanguage) && localStorage.getItem(localStorageLanguage) === enLanguage) {
      this.translate.setDefaultLang(enLanguage);
      localStorage.setItem(localStorageLanguage, enLanguage);
    }else {
      this.translate.setDefaultLang(zhHKLanguage);
      localStorage.setItem(localStorageLanguage, zhHKLanguage);
    }
    // if (this.translate.getBrowserLang() !== undefined){
    //   this.translate.use(this.translate.getBrowserLang());
    //   localStorage.setItem(localStorageLanguage, enLanguage);
    //   console.log('en')
    // }else {
    //   this.translate.use(zhHKLanguage);
    //   console.log('hk')
    // }
  }


  ngOnInit(): void {
    // 判断是什么类型的设备
    this.platform.ready().then(() => {
      console.log(getPlatforms());
      // this.utilsService.pcFlag = this.platform.is('desktop') || this.platform.is('electron');
      this.utilsService.pcFlag = !(this.platform.is('android') || this.platform.is('ios')
        || this.platform.is('ipad') || this.platform.is('iphone'));
      this.isIOS = this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone');
    });
    this.utilsService.loginFlag = localStorage.getItem(localStorageTokenName) != null && localStorage.getItem(localStorageTokenName) !== '';
    // todo test
    setTimeout(() => {
      // this.utilsService.sign();
    }, 600);
    //获取弹窗配置
    this.apiService.getPopup().subscribe((data) => {
      if (data.code === 200) {
        this.popupList = data.data;
        for (const pop of this.popupList) {
          if (!localStorage.getItem(popupStorage + pop.id)
            || (!pop.onlyOne && (new Date().getTime() - Number(localStorage.getItem(popupStorage + pop.id)))/1000 > pop.freq)) {
            this.popup = pop;
            this.popupShow = true;
            break;
          }
        }
      }
    });
    if (this.utilsService.loginFlag) {
      this.apiService.studentInfo().subscribe((data) => {
        if (data.code === 200) {
          this.utilsService.allowPurchasesFlag = data.data.allowPurchases;
          this.utilsService.userInfo = data.data;
          localStorage.setItem(localStorageLogin, '1');
        }
      });
    }
    // 定时检测登录情况
    this.timer = setInterval(() => {
      this.utilsService.loginFlag = localStorage.getItem(localStorageLogin) != null && localStorage.getItem(localStorageLogin) === '1';
    }, 1000);
  }

  ngOnDestroy(): void{
    clearInterval(this.timer);
  }

  changeLanguage(): void {
    if (this.utilsService.enFlag) {
      this.language = zhHKLanguage;
      this.translate.use(zhHKLanguage);
      this.utilsService.changeI18n(false);
      localStorage.setItem(localStorageLanguage, zhHKLanguage);
    } else {
      this.language = enLanguage;
      this.translate.use(enLanguage);
      this.utilsService.changeI18n(true);
      localStorage.setItem(localStorageLanguage, enLanguage);
    }
  }

  async routerAccount() {
    const loading = await this.loadingController.create({
      message: this.utilsService.loadingText
    });
    await loading.present();
    setTimeout(() => {
      this.router.navigate(['/folder/account/' + randomSixDigitCharacter()], {queryParams: {type: 0}});
    }, 200);
  }

  nameOfUI(firstName: string, lastName: string): string {
    return nameRules(firstName, lastName);
  }

  showSwitchModal(): void {
    this.switchModalFlag = true;
  }

  handleCancelOfSwitch(): void {
    this.switchModalFlag = false;
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  switchAccount(item: SignOtherAccount): void {
    if (this.utilsService.userInfo.id === item.studentId) {
      return;
    }
    this.apiService.switchStudent(item.studentId).subscribe((data) => {
      if (data.code === 200) {
        this.utilsService.allowPurchasesFlag = data.data.allowPurchases;
        this.utilsService.userInfo = data.data;
        localStorage.setItem(localStorageLogin, '1');
        this.dismiss();
        this.message.success('切換成功');
        this.switchAccountRoute();
      }
    });
  }

  async switchAccountRoute() {
    const loading = await this.loadingController.create({
      message: this.utilsService.loadingText
    });
    await loading.present();
    setTimeout(() => {
      this.router.navigate(['/folder/index/' + randomSixDigitCharacter()]);
    }, 200);
  }

  closePopup(): void {
    // 关闭时标记这次弹窗
    localStorage.setItem(popupStorage + this.popup.id, new Date().getTime().toString());
    this.popupShow = false;
    // 寻找是否有下一个需要弹窗的
    setTimeout(() => {
      for (const pop of this.popupList) {
        if (pop.sort > this.popup.sort && (!localStorage.getItem(popupStorage + pop.id)
          || (!pop.onlyOne && (new Date().getTime() - Number(localStorage.getItem(popupStorage + pop.id)))/1000 > pop.freq))) {
          this.popup = pop;
          this.popupShow = true;
          break;
        }
      }
    }, 1000);
  }

  changeImg(n: number): void {
    this.selectImgIndex = n;
  }
}
