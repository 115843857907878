// 日期格式
export const yearFormat = 'yyyy';
export const monthFormat = 'MMM';
// 日期时间格式
export const dateTimeFormat = 'HH:mm dd/MM/yyyy';
export const timeFormat = 'HH:mm';
export const dateFormat = 'dd/MM/yyyy';

// 日期时间格式2
export const dateTimeFormat2 = 'yyyy-MM-dd HH:mm';

export const zhHKLanguage = 'zh-HK';
export const enLanguage = 'en';
// token在localStorage中保存的key
export const localStorageTokenName = 'dance-platform-token';
export const localStorageLanguage = 'dance-platform-language';
export const localStorageLogin = 'dance-login';
export const localStorageType = 'dance-platform-type';

// 预约以及取消配置
export const freeCancelBookMilliseconds = 360;
export const bookEndMilliseconds = 30;
// 弹窗前缀
export const popupStorage = 'dance-popup';

// 角色默认权限
export const defaultPermissionList = [
  'MicInformation_Read',
  'MicInformation_Write',
  'RfidCalibration_Read',
  'RfidCalibration_Write',
  'MatchingVerification_Read',
  'MatchingVerification_Write',
  'MicInspection_Read',
  'Factory_Read',
  'Factory_Write',
  'TransitWarehouse_Read',
  'TransitWarehouse_Write',
  'Site_Read',
  'Site_Write',
  'User_Read',
  'User_Write',
  'Role_Read',
  'Role_Write',
  'ScannerApp',
];

// 默认背景高度
export const defaultBackgroundHeight = 1984;
// 加高背景高度
export const largeBackgroundHeight = 2806;
// 默认课表容量
export const defaultWeekMax = 7;
// 加大版学生课表容量
export const largeWeekMax = 10;
