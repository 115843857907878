import { Component, OnInit } from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {
  CourseScheduleStatusEnum,
  CourseScheduleWeek,
  CourseScheduleWeekItem,
  StudentTypeEnum
} from '../../common/interface';
import {CalendarWeek} from '../index/index.component';
import {addDays, format, subDays} from 'date-fns';
import {
  dateFormat,
  defaultBackgroundHeight,
  defaultWeekMax,
  largeBackgroundHeight,
  largeWeekMax,
  localStorageType
} from '../../common/config';
import {HttpParams} from '@angular/common/http';
import {ApiService} from '../../common/api.service';
import {UtilsService} from '../../common/utils.service';
import {MessageService} from '../../common/message.service';

@Component({
  selector: 'app-timetable-view',
  templateUrl: './timetable-view.component.html',
  styleUrls: ['./timetable-view.component.scss', '../index/index.component.scss'],
})
export class TimetableViewComponent implements OnInit {

  schedulingImg: SafeUrl = '../../../assets/scheduling/0.png';
  isPosterVisible = false;
  isPosterConfirmLoading  = false;
  posterWeekData!: CourseScheduleWeek | null;
  weekLoading = false;

  // 课程表缩放比例
  enlargeNum = 80;
  // 课表背景尺寸
  backgroundHeight: number = defaultBackgroundHeight;
  // 课表最大容量数
  weekMax: number = defaultWeekMax;

  // 预览比例
  viewScale = 1;

  onePosterWeekCalendarList: CalendarWeek[] = [];
  backgroundSize = 2;// 0-小号，1-默认，2-大号
  backgroundSizeOffset = -290;// 不同背景，距离顶部的偏移也不一样。目前的背景偏移对照

  childrenType = StudentTypeEnum.CHILDREN.valueOf();
  courseScheduleStatusCancel = CourseScheduleStatusEnum.CANCEL.toString();

  mouseDownScrollPosition: any;
  source: any;
  substituteType: CourseScheduleStatusEnum = CourseScheduleStatusEnum.SUBSTITUTE;
  nowYear = new Date().getFullYear();
  posterStudentType = StudentTypeEnum.ADULTS.toString();

  constructor(
    private apiService: ApiService,
    public utilsService: UtilsService,
    private message: MessageService,
  ) { }

  ngOnInit() {
    // 判断学员类型
    const type = localStorage.getItem(localStorageType);
    this.posterStudentType = type != null && type === '1' ? StudentTypeEnum.CHILDREN.toString() : StudentTypeEnum.ADULTS.toString();
    this.weekOnInit();
    this.getWeek();
  }

  /** 课程表生成 **/
  weekOnInit(): void {
    let dateList: Date[] = [];
    const now = new Date();
    // 判断现在是周几
    let nowDay = now.getDay();
    dateList.push(now);
    // getDay周日会返回 0，转成7方便操作
    if (nowDay === 0) {
      nowDay = 7;
    }
    if (nowDay !== 1) {
      // 补齐现在之前的时间
      for (let i = 1; i < nowDay; i++) {
        dateList.push(subDays(now, i));
      }
      // 补齐现在之后的时间
      for (let i = 1; i <= 7 - nowDay; i++) {
        dateList.push(addDays(now, i));
      }
    }else {
      // 补齐现在之后的时间
      for (let i = 1; i < 7; i++) {
        dateList.push(addDays(now, i));
      }
    }
    // 日期排序
    dateList = dateList.sort((item1: Date, item2: Date) => item1.getTime() - item2.getTime());
    // 给第一周赋值
    for (const item of dateList) {
      this.onePosterWeekCalendarList.push({
        name: format(item, dateFormat),
        date: item
      });
    }
  }

  posterModalDateToStr(date: Date): string {
    return format(date, 'd / M');
  }

  downloadPoster(): void {
    this.isPosterConfirmLoading = true;
    this.isPosterConfirmLoading = false;
  }

  getWeek(): void {
    this.weekLoading = true;
    this.apiService.getCourseScheduleWeek(new HttpParams().set('week', 0).set('studentType', this.posterStudentType)).subscribe((data) => {
      setTimeout(() => {
        this.weekLoading = false;
      }, 500);
      if (data.code === 200) {
        // 判断使用多大的背景
        let size =  data.data.monday.length;
        if (data.data.tuesday.length > size) {
          size = data.data.tuesday.length;
        }
        if (data.data.wednesday.length > size) {
          size = data.data.wednesday.length;
        }
        if (data.data.thursday.length > size) {
          size = data.data.thursday.length;
        }
        if (data.data.friday.length > size) {
          size = data.data.friday.length;
        }
        if (data.data.saturday.length > size) {
          size = data.data.saturday.length;
        }
        if (data.data.sunday.length > size) {
          size = data.data.sunday.length;
        }
        this.backgroundHeight = defaultBackgroundHeight;
        this.weekMax = defaultWeekMax
        console.log(size);
        // 兒童課表
        if (this.posterStudentType === StudentTypeEnum.CHILDREN.toString()) {
          if (size < 7) {
            this.backgroundSize = 3;
            this.backgroundSizeOffset = -180;
          }else {
            this.weekMax = largeWeekMax;
            this.backgroundHeight = largeBackgroundHeight;
            this.backgroundSize = 4;
            this.backgroundSizeOffset = -305;
          }
        } else {
          if (size < 6) {
            this.backgroundSize = 0;
            this.backgroundSizeOffset = 0;
          }else if (size < 7) {
            this.backgroundSize = 1;
            this.backgroundSizeOffset = -180;
          }else {
            this.backgroundSize = 2;
            this.backgroundSizeOffset = -290;
          }
        }
        this.posterWeekData = data.data;
        this.posterWeekData.monday.forEach(item => {
          item.timeWeb = this.dateTimeToTimeWeb(item.startTime, item.endTime);
        });
        this.posterWeekData.tuesday.forEach(item => {
          item.timeWeb = this.dateTimeToTimeWeb(item.startTime, item.endTime);
        });
        this.posterWeekData.wednesday.forEach(item => {
          item.timeWeb = this.dateTimeToTimeWeb(item.startTime, item.endTime);
        });
        this.posterWeekData.thursday.forEach(item => {
          item.timeWeb = this.dateTimeToTimeWeb(item.startTime, item.endTime);
        });
        this.posterWeekData.friday.forEach(item => {
          item.timeWeb = this.dateTimeToTimeWeb(item.startTime, item.endTime);
        });
        this.posterWeekData.saturday.forEach(item => {
          item.timeWeb = this.dateTimeToTimeWeb(item.startTime, item.endTime);
        });
        this.posterWeekData.sunday.forEach(item => {
          item.timeWeb = this.dateTimeToTimeWeb(item.startTime, item.endTime);
        });
        // 设置缩放比例
        if (this.backgroundSize === 4 && size >= 7) {
          const widthEnlargeNum = document.getElementById('timetable-view').offsetWidth / 2806 * 100;
          const heightEnlargeNum = document.getElementById('timetable-view').offsetHeight / 2806 * 100;
          // console.log(document.getElementById('timetable-view').offsetWidth, document.getElementById('timetable-view').offsetHeight);
          // console.log(widthEnlargeNum, heightEnlargeNum);
          this.enlargeNum = widthEnlargeNum > heightEnlargeNum && this.utilsService.pcFlag ? heightEnlargeNum : widthEnlargeNum;
        }else {
          this.enlargeNum = document.getElementById('timetable-view').offsetWidth / 2806 * 100;
        }
      }
    });
  }

  dateTimeToTimeWeb(startTime: number, endTime: number): string {
    return `${this.dateTimeToTimeString(new Date(startTime))}-${this.dateTimeToTimeString(new Date(endTime))}`;
  }

  dateTimeToTimeString(date: Date): string {
    return format(date, 'p');
  }

  borderColorByType(item: CourseScheduleWeekItem): string {
    if (item.mvClass) {
      return '#faf7b4';
    }
    if (item.type === this.childrenType) {
      return '#CB67FF';
    }
    return '#e8e8e8';
  }

  /** 课程表生成 END **/
  mousedownTimeTable(e) {
    const scrollContainer = document.getElementById('timeTable');
    this.mouseDownScrollPosition = {
      scrollLeft: scrollContainer.scrollLeft,
      scrollTop: scrollContainer.scrollTop
    };
    // 记录初始 client 位置，用于计算移动距离
    this.source = {
      x: e.clientX,
      y: e.clientY
    };

    document.getElementById('timetable-view').onmousemove = (event: MouseEvent) => {
      //鼠标滑动的实时距离
      const dragMoveDiff = {
        x: this.source.x - event.clientX,
        y: this.source.y - event.clientY
      };
      document.getElementById('timeTable').scrollLeft = this.mouseDownScrollPosition.scrollLeft + dragMoveDiff.x;
      document.getElementById('timeTable').scrollTop = this.mouseDownScrollPosition.scrollTop + dragMoveDiff.y;
    };
    document.onmouseup = (event: MouseEvent) => {
      document.getElementById('timetable-view').onmousemove = null;
      document.getElementById('timetable-view').onmouseup = null;
    };
  }

  updateScale(num: number): void {
    if (this.viewScale <= 1 && num < 0) {
      return;
    }
    if (this.viewScale >= 4 && num > 0) {
      return;
    }
    this.viewScale += num;
  }

  closeView(): void {
    this.utilsService.timetableViewShow = false;
  }

  leftClick(i: number): number {
    if (this.posterStudentType === StudentTypeEnum.CHILDREN.toString()) {
      if (this.backgroundSize === 3) {
        return 426 + i * 304;
      }else {
        return 426 + i * 306;
      }
    }
    return 426 + i * 308;
  }

  itemLeftClick(i: number): string {
    if (this.posterStudentType === StudentTypeEnum.CHILDREN.toString()) {
      if (this.backgroundSize === 3) {
        return 350 + i * 304 + 'px';
      }else {
        return 350 + i * 306 + 'px';
      }
    }
    return 350 + i * 308 + 'px';
  }

}
