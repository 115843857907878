import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {LoginRequest, Response, User} from './interface';
import {
  localStorageLogin,
  localStorageTokenName
} from './config';
import {Router} from '@angular/router';
import {UtilsService} from './utils.service';
import {randomSixDigitCharacter} from './utils';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // 异步获取user会导致导航数据无法初始化，所以改成同步获取
  // user = new Subject<User>();
  // 用户权限控制
  permissionValueSet = new Set();

  constructor(
    private apiService: ApiService,
    private router: Router,
    private utilsService: UtilsService,
    private loadingController: LoadingController,
  ) {
    Object.assign(this, { localStorageTokenName });
  }

  // async login(loginRequest: LoginRequest) {
  //   const data = await this.loginPromise(loginRequest);
  //   if (data.code === 200) {
  //     localStorage.setItem(localStorageTokenName, data.data.token);
  //     this.permissionValueSet.clear();
  //     for (const item of data.data.permissionList ? data.data.permissionList : []) {
  //       this.permissionValueSet.add(item);
  //     }
  //     this.utilsService.loginFlag = true;
  //     location.reload();
  //     // this.router.navigate(['/folder/index']);
  //   }else {
  //     localStorage.removeItem(localStorageTokenName);
  //   }
  // }

  async logout() {
    this.utilsService.loginFlag = false;
    this.utilsService.allowPurchasesFlag = false;
    localStorage.removeItem(localStorageTokenName);
    localStorage.setItem(localStorageLogin, '0');
    await this.logoutPromise();
    const loading = await this.loadingController.create({
      message: this.utilsService.loadingText
    });
    await loading.present();
    setTimeout(() => {
      this.router.navigate(['/folder/index/' + randomSixDigitCharacter()]);
    }, 200);
  }

  // async getLoginInfo() {
  //   const data = await this.apiService.getLoginInfo();
  //   this.user = data.data;
  //   localStorage.setItem(localStorageTokenName, data.data.token);
  // }

  loginPromise(loginRequest: LoginRequest): Promise<Response<User>> {
    return this.apiService.login(loginRequest).toPromise<Response<User>>();
  }

  logoutPromise(): Promise<Response<any>> {
    return this.apiService.logout().toPromise<Response<any>>();
  }

  hasPromise(code: string): boolean {
    if (!code || code === '') {
      return false;
    }
    return this.permissionValueSet.has(code);
  }
}
