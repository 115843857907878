import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import {LoadingController, ModalController} from '@ionic/angular';
import {SignUpModalComponent} from '../sign-up-modal/sign-up-modal.component';
import {CourseScheduleStatusEnum, ReserveStatusEnum, Sign} from './interface';
import {ApiService} from './api.service';
import {enLanguage, localStorageLanguage, localStorageLogin} from './config';
import {Router} from '@angular/router';
import {randomSixDigitCharacter} from './utils';
import {ClassesNumberModalComponent} from '../classes-number-modal/classes-number-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  // 是否显示课表弹窗
  timetableViewShow = false;
  // 是否已登录
  loginFlag = false;
  // 是否为PC端
  pcFlag = false;
  // 使用的语言是否为英语
  enFlag = false;
  // 是否允许购买
  allowPurchasesFlag = true;
  // 支付弹窗是否还未弹窗
  payModalFlag = true;
  // 当前登录用户信息
  userInfo: Sign;
  // 多语言
  cancelBookText = '';
  cancelBookMsg = '';
  noText = 'no';
  yesText = 'yes';
  accountText = '';
  passwordText = '';
  passwordsDigitsText = '';
  accountEmptyText = '';
  loginSuccessText = '';
  loginErrorText = '';
  cardLengthErrText = '';
  isRequiredText = '';
  wrongFormatText = '';
  firstNameText = '';
  lastNameText = '';
  genderText = '';
  typeText = '';
  emailText = '';
  areaCodeText = '';
  phoneNumberText = '';
  addCardText = '';
  registerText = '';
  successText = '';
  errorText = '';
  updatePasswordText = '';
  editText = '';
  exchangeSuccessText = '';
  exchangeModalText = '';
  exchangeModalMessageText = '';
  exchangeStarsNotEnoughText = '';
  loadingText = '';
  courseCancelledText = '';
  bookClosedText = '';
  bookEndText = '';
  permanentText = '';
  alreadyPurchasedText = '';
  sendAlreadyPurchasedText = '';
  emailErrText = '';
  emailCodeText = '';
  sendForgetPasswordEmailText = '';
  repeatPasswordText = '';
  birthdateText = '';
  studentCardText = '';
  studentCardExpiryDateText = '';
  ageText = '';
  noClassNumberBookedText = '';
  noVerificationCodeText = '';
  normalText = '';
  substituteText = '';
  cancelText = '';
  notTimeoutCancelText = '';
  timeoutCancelText = '';
  substituteCancelText = '';
  courseCancelText = '';
  sortTips2Text = '';
  sortTips3Text = '';
  sortTips4Text = '';
  sortTips5Text = '';
  bookTips = '';

  constructor(
    private router: Router,
    public translate: TranslateService,
    public modalController: ModalController,
    private apiService: ApiService,
    private loadingController: LoadingController,
  ) {
    // 读取语言配置
    if (localStorage.getItem(localStorageLanguage)) {
      this.enFlag = localStorage.getItem(localStorageLanguage) === enLanguage;
    }
  }

  changeI18n(flag: boolean): void {
    this.enFlag = flag;
    this.i18nOnInit();
  }
  i18nOnInit(): void {
    // 初始化多语言
    this.translate.get(['isCancelBook', 'no', 'yes', 'isCancelBookMsg', 'account', 'password', 'passwords_digits',
      'account_empty', 'login_success', 'login_error', 'card_length_err', 'is_required', 'wrong_format','first_name',
      'last_name','gender','type','email','add_card','register', 'success', 'error', 'update_password', 'edit',
      'exchange_success','exchange_modal','exchange_modal_message', 'exchange_stars_not_enough','loading','course_cancelled',
      'book_closed', 'book_end', 'permanent', 'already_purchased', 'send_already_purchased', 'email_is_null', 'email_code',
      'send_forget_password_email','repeat_password','birthdate','student_card','student_card_expiry_date','age',
      'no_class_number_booked', 'no_verification_code', 'phone_number', 'areaCode', 'NORMAL', 'SUBSTITUTE', 'CANCEL',
      'NOT_TIMEOUT_CANCEL', 'TIMEOUT_CANCEL', 'SUBSTITUTE_CANCEL', 'COURSE_CANCEL', 'sort_tips2', 'sort_tips3', 'sort_tips4',
      'sort_tips5', 'book_tips'])
      .subscribe((data: any) => {
        console.log(data);
        this.bookTips = data.book_tips;
        this.sortTips2Text = data.sort_tips2;
        this.sortTips3Text = data.sort_tips3;
        this.sortTips4Text = data.sort_tips4;
        this.sortTips5Text = data.sort_tips5;
        this.notTimeoutCancelText = data.NOT_TIMEOUT_CANCEL;
        this.timeoutCancelText = data.TIMEOUT_CANCEL;
        this.substituteCancelText = data.SUBSTITUTE_CANCEL;
        this.courseCancelText = data.COURSE_CANCEL;
        this.normalText = data.NORMAL;
        this.substituteText = data.SUBSTITUTE;
        this.cancelText = data.CANCEL;
        this.noVerificationCodeText = data.no_verification_code;
        this.cancelBookText = data.isCancelBook;
        this.noText = data.no;
        this.yesText = data.yes;
        this.cancelBookMsg = data.isCancelBookMsg;
        this.accountText = data.account;
        this.passwordText = data.password;
        this.passwordsDigitsText = data.passwords_digits;
        this.accountEmptyText = data.account_empty;
        this.loginSuccessText = data.login_success;
        this.loginErrorText = data.login_error;
        this.cardLengthErrText = data.card_length_err;
        this.isRequiredText = data.is_required;
        this.wrongFormatText = data.wrong_format;
        this.firstNameText = data.first_name;
        this.lastNameText = data.last_name;
        this.genderText = data.gender;
        this.typeText = data.type;
        this.emailText = data.email;
        this.areaCodeText = data.areaCode;
        this.phoneNumberText = data.phone_number;
        this.addCardText = data.add_card;
        this.registerText = data.register;
        this.successText = data.success;
        this.errorText = data.error;
        this.updatePasswordText = data.update_password;
        this.editText = data.edit;
        this.exchangeSuccessText = data.exchange_success;
        this.exchangeModalText = data.exchange_modal;
        this.exchangeModalMessageText = data.exchange_modal_message;
        this.exchangeStarsNotEnoughText = data.exchange_stars_not_enough;
        this.loadingText = data.loading;
        this.courseCancelledText = data.course_cancelled;
        this.bookClosedText = data.book_closed;
        this.bookEndText = data.book_end;
        this.permanentText = data.permanent;
        this.alreadyPurchasedText = data.already_purchased;
        this.sendAlreadyPurchasedText = data.send_already_purchased;
        this.emailErrText = data.email_err;
        this.emailCodeText = data.email_code;
        this.sendForgetPasswordEmailText = data.send_forget_password_email;
        this.repeatPasswordText = data.repeat_password;
        this.birthdateText = data.birthdate;
        this.studentCardText = data.student_card;
        this.studentCardExpiryDateText = data.student_card_expiry_date;
        this.ageText = data.age;
        this.noClassNumberBookedText = data.no_class_number_booked;
    });
  }

  async login() {
    const modal = await this.modalController.create({
      component: LoginModalComponent,
      mode: 'ios',
      cssClass: this.pcFlag ? 'app-modal app-login-modal-pc' : '',
      // componentProps: {
      //   courseSchedule: item
      // }
    });
    // 登录窗口关闭回调
    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null && modelData.data) {
        // 登錄成功后的操作
        this.loginFlag = true;
        this.apiService.studentInfo().subscribe((data) => {
          if (data.code === 200) {
            this.allowPurchasesFlag = data.data.allowPurchases;
            this.userInfo = data.data;
            // 剩余课堂少于3时，弹窗提示
            if (this.userInfo.insufficientBalance && this.userInfo.courseBalance < 3) {
              this.classesNumberModal();
            }
            localStorage.setItem(localStorageLogin, '1');
          }
        });
        this.routerIndex();
        // location.reload();
      }
    });
    await modal.present();
  }

  async classesNumberModal() {
    const modal = await this.modalController.create({
      component: ClassesNumberModalComponent,
      mode: 'ios',
      cssClass: this.pcFlag ? 'app-modal app-classes-number-modal-pc' : 'app-modal app-classes-number-modal',
    });
    // 登录窗口关闭回调
    modal.onDidDismiss().then((modelData) => {
    });
    await modal.present();
  }

  async routerIndex() {
    const loading = await this.loadingController.create({
      message: this.loadingText
    });
    await loading.present();
    setTimeout(() => {
      this.router.navigate(['/folder/index/' + randomSixDigitCharacter()]);
    }, 200);
  }

  async sign() {
    const modal = await this.modalController.create({
      component: SignUpModalComponent,
      mode: 'ios',
      cssClass: this.pcFlag ? 'app-modal app-sign-modal-pc' : '',
      // componentProps: {
      //   courseSchedule: item
      // }
    });
    // 登录窗口关闭回调
    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null && modelData.data) {
        // todo 注冊成功后的操作
        // location.reload();
      }
    });
    await modal.present();
  }

  //判断是否登录，否的话触发登录注册弹窗
  checkLogin(): boolean {
    if (this.loginFlag && localStorage.getItem(localStorageLogin) === '1') {
      return true;
    }else {
      this.login();
      return false;
    }
  }

  navigateByUrlOfRefresh(uri: string, queryParams: any): void {
    // 切换时重新加载路由页面
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri], queryParams)
    );
  }

  courseScheduleStatusToUIName(studentType: string | undefined): string {
    switch (studentType) {
      case CourseScheduleStatusEnum.NORMAL.toString():
        return this.normalText;
      case CourseScheduleStatusEnum.SUBSTITUTE.toString():
        return this.substituteText;
      case CourseScheduleStatusEnum.CANCEL.toString():
        return this.cancelText;
      default:
        return '-';
    }
  }

  reserveStatusToString(status: ReserveStatusEnum | undefined): string {
    switch (status) {
      case ReserveStatusEnum.NORMAL.toString():
        return this.normalText;
      case ReserveStatusEnum.NOT_TIMEOUT_CANCEL.toString():
        return this.notTimeoutCancelText;
      case ReserveStatusEnum.TIMEOUT_CANCEL.toString():
        return this.timeoutCancelText;
      case ReserveStatusEnum.SUBSTITUTE_CANCEL.toString():
        return this.substituteCancelText;
      case ReserveStatusEnum.COURSE_CANCEL.toString():
        return this.courseCancelText;
      default:
        return '-';
    }
  }
}
