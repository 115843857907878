import { Injectable } from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private toastController: ToastController
  ) {
  }

  async error(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      // color: 'danger',
      color: 'light',
      position: 'top',
      duration: 2000,
      cssClass: 'app-message-danger',
      buttons: [
        {
          side: 'start',
          icon: 'alert-circle',
          handler: () => {
          }
        }, {
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    toast.present();
  }

  async warning(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      // color: 'warning',
      color: 'light',
      position: 'top',
      duration: 2000,
      cssClass: 'app-message-warning',
      buttons: [
        {
          side: 'start',
          icon: 'alert-circle',
          handler: () => {
          }
        }, {
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    toast.present();
  }

  async success(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      // color: 'success',
      color: 'light',
      position: 'top',
      duration: 2000,
      cssClass: 'app-message-success',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle',
          handler: () => {
          }
        }, {
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    toast.present();
  }

  async successByPosition(msg: string, location: 'top' | 'bottom' | 'middle', time: number) {
    const toast = await this.toastController.create({
      message: msg,
      color: 'light',
      position: location,
      duration: time,
      cssClass: 'app-message-success',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle',
          handler: () => {
          }
        }, {
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    toast.present();
  }

  async successByTime(msg: string, time: number) {
    const toast = await this.toastController.create({
      message: msg,
      // color: 'success',
      color: 'light',
      position: 'top',
      duration: time,
      cssClass: 'app-message-success',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle',
          handler: () => {
          }
        }, {
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    toast.present();
  }


}
