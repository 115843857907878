export interface User {
  account: string;
  name?: string;
  token: string;
  allowPurchases: boolean;
  permissionList: PermissionShow[];
}

export interface Permissions {
  froms: Permission[];
  accessControl: Permission[];
  recycleBin: Permission[];
  notification: Permission[];
  manual: Permission;
}

export interface PermissionShow {
  id: string;
  operationType: string;
  permission: string;
  vesselId?: string;
  templateId?: string;
}

export interface Permission {
  permissionId: string;
  permission: string;
  value?: any;
  permissions?: Permission[];
  operationType?: string;
}


export interface Captcha {
  img: string;
  verKey?: string;
}

export interface LoginRequest {
  account: string;
  password: string;
  // userType: string;
  // verKey?: string;
  // verCode?: string;
}

export interface UpdatePassword {
  password: string;
  confirmPassword: string;
}

// 公共类
export interface PageRequest<T> {
  pageNo: number;
  pageSize: number;
  data: T;
}

export interface Response<T> {
  code: number;
  msg: string;
  cnMsg: string;
  data: T;
}

export interface ResponseList<T> {
  code: number;
  msg: string;
  data: T[];
}

export interface Page<T> {
  code: number;
  msg: string;
  data: {
    pageNo: number;
    pages: number;
    pageSize: number;
    total: number;
    list: T[];
  };
}

export interface Notice {
  id?: string;
  message?: string;
  sort?: number;
  index: string;
  type: string;
}

export enum NoticeType {
  text = 'text',
  link = 'link'
}

export interface CourseSchedule {
  id?: string;
  courseName?: string;
  type?: string;
  difficulty?: string;
  stars?: boolean;
  tag?: string;
  startTime?: number;
  endTime?: number;
  teacherName?: string;
  teacherPhoto?: string;
  roomName?: string;
  status?: string;
  capacityFull?: boolean;
  reserveCount?: number;
  reserveFlag?: boolean;
  waitingFlag?: boolean;
  waitingCount?: number;
  timeWeb?: string;
  expired?: boolean;
  reserveId?: string;
  waitingId?: string;
  signIn: boolean;
  mvClass: boolean;
}

export interface StudentSchedule {
  name?: string;
  difficulty?: string;
  stars?: boolean;
  tag?: string;
  teacherName?: string;
  startTime?: number;
  endTime?: number;
  status?: string;
  type?: string;
  reserveFlag?: boolean;
  reserveStatus?: string;
  waitingFlag?: boolean;
  waitingStatus?: string;
  timeWeb?: string;
  expired?: boolean;
  reserveId?: string;
  waitingId?: string;
  signIn: boolean;
  mvClass: boolean;
}

export interface StudentSchedule {
  name?: string;
  difficulty?: string;
  stars?: boolean;
  tag?: string;
  teacherName?: string;
  startTime?: number;
  endTime?: number;
  status?: string;
  type?: string;
  reserveFlag?: boolean;
  reserveStatus?: string;
  waitingFlag?: boolean;
  waitingStatus?: string;
  courseScheduleId?: string;
}

export interface StudentCoursePackage {
  ownedStarQuantity: number;
  exchangeStarQuantity: number;
  surplusCourseQuantity: number;
  packageList: StudentCoursePackageList[];
}

export interface StudentCoursePackageList {
  id?: string;
  studentId?: string;
  name?: string;
  surplusQuantity?: number;
  totalQuantity?: number;
  previousQuantity?: number;
  delayedQuantity?: number;
  useDelayed?: boolean;
  expirationTime?: number;
  orderId?: string;
  status?: string;
  getTime?: number;
  validityPeriod?: number;
  type?: string;
  firstCourseTime?: number;
}

export interface StudentClassRecord {
  courseName?: string;
  difficulty?: string;
  teacherName?: string;
  roomName?: string;
  startTime?: number;
  endTime?: number;
  signIn?: boolean;
  timeWeb?: string;
  dateWeb?: string;
  status?: CourseScheduleStatusEnum;
  reserveStatus?: ReserveStatusEnum;
}

export interface RegisterVerificationCode {
  email: string;
}

export interface ResetPassword {
  token: string;
  password: string;
}

export interface Order {
  orderId?: string;
  packageName?: string;
  price?: number;
  totalAmount?: number;
  payAmount?: number;
  quantity?: number;
  orderStatus?: string;
  payTime?: number;
  createTime?: number;
  dateWeb?: string;
  validityPeriod?: number;
  delayedQuantity?: number;
  courseQuantity?: number;
}

export interface CoursePackage {
  id?: string;
  code?: string;
  name?: string;
  courseQuantity?: number;
  price?: number;
  validityPeriod?: number;
  delayedQuantity?: number;
  updateUser?: string;
  updateTime?: number;
  type?: string;
  deleteFlag?: boolean;
  stripePriceId?: string;
  selectFlag?: boolean;
  quantity?: number;
  subtotal?: number;
}

export interface PlaceOrder {
  coursePackagePriceId?: string;
  quantity?: number;
}

export interface CourseScheduleBook {
  courseScheduleId: string;
  autoCancelTime?: number;
}

export interface SignRequest {
  account: string;
  password: string;
  firstName: string;
  lastName: string;
  gender: string;
  areaCode?: string;
  phone?: string;
  email: string;
  type: string;
  card?: string;
  cardExpirationTime?: number;
  birthday?: number;
  verificationCode: string;
  bankCardList: BankCardRequest[];
}

export interface Sign {
  id?: string;
  account: string;
  password: string;
  firstName: string;
  lastName: string;
  gender: string;
  areaCode?: string;
  phone?: string;
  email: string;
  verificationCode?: string;
  type: string;
  card?: string;
  cardExpirationTime?: number;
  birthday?: number;
  allowPurchases?: boolean;
  courseBalance?: number;
  insufficientBalance?: boolean;
  bankCardList: BankCard[];
  cardExpirationTimeUI?: string;
  birthdayUI?: string;
  yearsOldUI?: string;
  master?: boolean;
  otherAccountList?: SignOtherAccount[];
}

export interface SignOtherAccount {
  master?: boolean;
  current?: boolean;
  studentName?: string;
  studentId?: string;
}

export interface BankCardRequest {
  cardNumber?: string;
  defaultCard?: boolean;
}

export interface BankCard {
  id?: string;
  studentId?: string;
  cardNumber?: string;
  defaultCard?: boolean;
}

export enum StudentTypeEnum {
  /**
   * 儿童
   */
  CHILDREN = 'CHILDREN',
  /**
   * 成人
   */
  ADULTS = 'ADULTS',
  /**
   * 中学生
   */
  HIGH_SCHOOL_STUDENTS = 'HIGH_SCHOOL_STUDENTS',
  /**
   * 大学生
   */
  COLLEGE_STUDENTS = 'COLLEGE_STUDENTS'
}

export enum DifficultyEnum {
  /**
   * 初级
   */
  BEGINNER = 'BEGINNER',
  /**
   * 中级
   */
  INTERMEDIATE = 'INTERMEDIATE',
  /**
   * 高级
   */
  ADVANCED = 'ADVANCED'
}

export enum CoursePackageStatusEnum {
  /**
   * 未启用
   */
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  /**
   * 已启用
   */
  ACTIVATED = 'ACTIVATED',
  /**
   * 过期
   */
  EXPIRED = 'EXPIRED',
  /**
   * 用完
   */
  FINISHED = 'FINISHED'
}

export enum CourseScheduleStatusEnum {
  /**
   * 正常
   */
  NORMAL = 'NORMAL',
  /**
   * 代课
   */
  SUBSTITUTE = 'SUBSTITUTE',
  /**
   * 取消
   */
  CANCEL = 'CANCEL'
}

export enum GenderEnum {
  /**
   * 男
   */
  MALE = 'MALE',
  /**
   * 女
   */
  FEMALE = 'FEMALE',
}

export enum ReserveStatusEnum {
  /**
   * 正常
   */
  NORMAL = 'NORMAL',
  /**
   * 未超时取消
   */
  NOT_TIMEOUT_CANCEL = 'NOT_TIMEOUT_CANCEL',
  /**
   * 超时取消
   */
  TIMEOUT_CANCEL = 'TIMEOUT_CANCEL',
  /**
   * 因代课取消
   */
  SUBSTITUTE_CANCEL = 'SUBSTITUTE_CANCEL',
  /**
   * 因课程取消
   */
  COURSE_CANCEL = 'COURSE_CANCEL'
}

export enum OrderStatusEnum {
  /**
   * 待支付
   */
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  /**
   * 支付成功
   */
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  /**
   * 取消
   */
  CANCEL = 'CANCEL',
}

export interface CourseInfo {
  selectNameOfWeb?: string;
  id?: string;
  code?: string;
  name?: string;
  type?: string;
  difficulty?: string;
  stars?: boolean;
  tag?: string;
  deleteFlag?: boolean;
  enable?: boolean;
}

export interface AvailableDanceRoom {
  id: string;
  name: string;
  available: boolean;
}

export interface CourseScheduleDetail {
  id?: string;
  courseId?: string;
  code?: string;
  name?: string;
  difficulty?: string;
  stars?: boolean;
  tag?: string;
  teacherId?: string;
  substituteTeacherId?: string;
  startTime: number;
  endTime: number;
  roomId?: string;
  changeRoom?: boolean;
  status?: string;
  periodic?: boolean;
  preCourseScheduleId?: string;
  updateUser?: string;
  updateTime: number;
  capacityFull?: boolean;
  version?: number;
  type?: string;
}

export interface CourseScheduleSave {
  id?: string;
  courseId?: string;
  teacherId?: string;
  startTime?: number;
  endTime?: number;
  roomId?: string;
  changeRoom?: boolean;
  status?: string;
  periodic?: boolean;
}

export interface CourseScheduleReserve {
  studentId?: string;
  courseScheduleId?: string;
}

export interface CourseScheduleStudent {
  studentId?: string;
  studentName?: string;
  signIn?: boolean;
  signTime?: number;
  personInCharge?: string;
}

export interface CourseStudentCheckIn {
  studentId?: string;
  studentCode?: string;
  studentName?: string;
  studentType?: string;
  courseScheduleId?: string;
  courseName?: string;
  teacherName?: string;
  startTime?: number;
  endTime?: number;
  roomName?: string;
  roomColor?: string;
  reserveId: string;
  signIn?: boolean;

  timeWeb?: string;
  errAlert?: boolean;
}


export interface Teacher {
  id?: string;
  name?: string;
  gender?: string;
  phone: string;
  index: string;
  color: string;
}

export interface CourseScheduleWeek{
  monday: CourseScheduleWeekItem[];
  tuesday: CourseScheduleWeekItem[];
  wednesday: CourseScheduleWeekItem[];
  thursday: CourseScheduleWeekItem[];
  friday: CourseScheduleWeekItem[];
  saturday: CourseScheduleWeekItem[];
  sunday: CourseScheduleWeekItem[];
}

export interface CourseScheduleWeekItem {
  id?: string;
  courseName?: string;
  type?: string;
  difficulty?: string;
  stars?: boolean;
  tag?: string;
  startTime: number;
  endTime: number;
  teacherName?: string;
  substituteTeacherName?: string;
  roomName?: string;
  status?: string;
  capacityFull?: boolean;
  reserveCount?: number;
  reserveFlag?: boolean;
  waitingFlag?: boolean;
  waitingCount?: number;
  reserveId?: string;
  waitingId?: string;
  imageDisplay: boolean;
  mvClass: boolean;

  timeWeb?: string;
}

export interface Popup {
  id: number;
  title: string;
  content: string;
  img?: string;
  sort: number;
  freq: number;
  onlyOne: boolean;
  imgList: string[];
}


