import {differenceInCalendarYears, format} from 'date-fns';


/**
 * date类型转成指定格式的日期字符串
 */
export function dateToStr(date: number | undefined, formatType: string | undefined): string {
  if (!date) {
    return '';
  }
  if (!formatType) {
    formatType = 'yyyy-MM-dd HH:mm:ss';
  }
  return format(new Date(date), formatType);
}

/**
 * 生成6位随机字符串
 */
export function randomSixDigitCharacter(): string {
  // /****默认不去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678oOLl9gqVvUuI1';
  const maxPos = chars.length;
  let code = '';
  for (let i = 0; i < 6; i++) {
    code += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return code;
}

/**
 * 校驗email格式是否錯誤
 */
export function checkEmailFormat(email: string): boolean {
  // eslint-disable-next-line max-len
  return !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

/**
 * 判断多少岁年龄
 */
export function yearsOld(birthdate: Date): number {
  const now = new Date();
  let old = differenceInCalendarYears(now, birthdate);
  // 当生日时间还没到时，不算一岁，故年龄-1
  if (birthdate.setFullYear(now.getFullYear()) > now.getTime()) {
    old--;
  }
  return old;
}

/**
 * 用户名根据规则输出
 */
export function nameRules(firstName: string, lastName: string): string {
  const pattern = /^(?:[\u4e00-\u9fa5·]{2,16})$/;
  //是否为中文名字
  if (pattern.test(firstName + lastName)) {
    return `${lastName}${firstName}`;
  }else {
    return `${firstName} ${lastName}`;
  }
}
